<script setup lang="ts">
const swiper = useSwiper();
const emit = defineEmits(['change', 'isLoaded']);
const props = defineProps({
  currentIndex: {
    type: Number,
    default: 0,
  },
});

watch(
  () => props.currentIndex,
  newIndex => {
    if (swiper.value && newIndex !== swiper.value.realIndex) swiper.value?.slideTo(newIndex);
  },
);

swiper?.value?.on('slideChange', () => {
  emit('change', swiper.value?.realIndex);
});

swiper?.value?.on('init', () => {
  emit('isLoaded');
});
// TODO handle the active item is not the first slide when the slides-per-view is auto
</script>
<template>
  <div />
</template>
